
import Vue, { PropType } from "vue";

import MyPreview from "@/components/MyPreview.vue";
import { Note, note } from "@/note";

export default Vue.extend({
  components: {
    MyPreview,
  },
  props: {
    note: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    merged(): Note {
      const n = JSON.parse(JSON.stringify(note));

      for (const [key, value] of Object.entries(
        JSON.parse(this.note) as Note
      )) {
        if (n[key]) {
          n[key].value = value.value;
        }
      }

      return n;
    },
  },
});
