
import { Property } from "@/interfaces";
import Vue, { PropType } from "vue";

import MyDetail from "@/components/property/MyDetail.vue";

export default Vue.extend({
  components: { MyDetail },
  props: {
    property: {
      type: Object as PropType<Property>,
      required: true,
    },
  },
});
