
import Vue, { PropType } from "vue";

import MyNote from "@/components/property/MyNote.vue";

export default Vue.extend({
  components: {
    MyNote,
  },
  props: {
    note: {
      type: String,
      default: '',
    },
  },
});
